.gradient {
  background: linear-gradient(
    -45deg,
    #ffe818,
    #ffe818,
    #ff1451,
    #9b36ff,
    #04b4ff
  );
  background-size: 400% 400%;
  animation: gradient 3s ease;
  animation-iteration-count: infinite;
  fill: linear-gradient(-45deg, #ffe818, #ffe818, #ff1451, #9b36ff, #04b4ff);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
